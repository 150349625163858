<script>
import { get } from 'lodash-es';

import navigation from '~/lib/navigation';

import Sidebar from '@/components/molecules/Sidebar';
import SidebarMixin from '@/components/molecules/Sidebar/mixins/SidebarMixin';
import ConfirmationModalControllerMixin from '@/mixins/ConfirmationModalControllerMixin';
import DisplayMixin from '@/mixins/DisplayMixin';
import LocationsControllerMixin from '@/mixins/Locations/LocationsControllerMixin';
import ModalControllerMixin from '@/mixins/ModalControllerMixin';
import { fetchAvatarImage } from '@/utilities/avatar-utilities';

export default defineNuxtComponent({
  name: 'Legacy',
  components: { Sidebar },
  mixins: [
    LocationsControllerMixin,
    DisplayMixin,
    ConfirmationModalControllerMixin,
    ModalControllerMixin,
    SidebarMixin,
  ],
  data() {
    return {
      user: {
        avatarImage: '',
        fullName: '',
      },
      availableLocations: [],
    };
  },
  async created() {
    this.availableLocations = await this.getLocationsOnly();

    const selectedNavigation = this.navigation.find(
      element =>
        $route.path.includes(element.url) || element.children?.some(child => child.url.includes($route.path)),
    );

    this.setSelectedNavigationItem(selectedNavigation ?? {});
    this.setSelectedLocation(this.availableLocations[0]);
  },
  computed: {
    navigation() {
      return navigation.filter(item => this.$auth.can(item.ability));
    },
    baseUrl() {
      return this.$baseURL;
    },
    checkedLocation() {
      return this.getSelectedLocation()?.id;
    },
    bothSidebarsCollapsed() {
      return this.isSidebarCollapsed && this.isSecondSidebarCollapsed;
    },
  },
  mounted() {
    this.getUser();
  },
  methods: {
    onLocationChanged(location) {
      this.setSelectedLocation(location);
    },
    getAvatarImage() {
      const avatar = get(this.$auth?.user.value, 'avatar');
      return fetchAvatarImage(avatar);
    },
    userFullname() {
      if (this.$auth.user.value) {
        const { firstname, insertion, lastname } = this.$auth?.user.value.meta;
        return [firstname, ...(insertion ? [insertion] : []), lastname].join(
          ' ',
        );
      }
      return '';
    },
    getUser() {
      this.user = {
        avatarImage: this.getAvatarImage(),
        fullName: this.userFullname(),
      };
    },
    onNavigationItemClick(item) {
      if (item.children?.length)
        this.setSidebarIsCollapsed(true);
    },
  },
});
</script>

<template>
  <div class="flex h-full">
    <Sidebar
      :user="user"
      :navigation="navigation"
      :locations="availableLocations"
      :checked-location="checkedLocation"
      @on-location-changed="onLocationChanged"
      @on-navigation-item-click="onNavigationItemClick"
    />
    <div
      class="flex w-full flex-col transition-all duration-200"
      :class="{
        'pl-28': bothSidebarsCollapsed,
        'pl-80': !bothSidebarsCollapsed,
      }"
    >
      <div class="flex flex-col">
        <slot />
      </div>
    </div>
  </div>
</template>

<style>
body {
  background-color: rgba(243, 246, 250, 1);
}
</style>
